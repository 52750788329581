code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: white;
}

:root, [data-joy-color-scheme="light"] {
  --joy-palette-background-backdrop: rgba(55 55 55 / 0.5);
}

*,
::after,
::before {
  box-sizing: border-box;
}

