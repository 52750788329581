  .drawer-container {
    --transition-speed: 0.3s;
  }
  
  .drawer {
    height: 100%;
    overflow: auto;
    position: fixed;
    transition: transform var(--transition-speed) ease;
    z-index: 1000;
  }
  
  .drawer.left {
    top: 0;
    left: 0;
    transform: translateX(-105%);
  }
  
  .drawer.right {
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
  
  .drawer.top {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 40%;
  }
  
  .drawer.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    height: 40%;
  }
  
  .drawer-container.in.open .left,
  .drawer-container.in.open .right {
    transform: translateX(0);
  }
  
  .drawer-container.in.open .top,
  .drawer-container.in.open .bottom {
    transform: translateY(0);
  }
  
  .backdrop {
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity var(--transition-speed) ease,
      visibility var(--transition-speed) ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    z-index: 0;
  }
  
  .drawer-container.in.open .backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  }
  