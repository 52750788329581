.muzeo-object-images {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c2c2c2;
    position: relative;
    width:100%;
    overflow: hidden;
  }

  .muzeo-object-images > .muzeo-object-image-prisma {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .muzeo-object-images .muzeo-object-images-actions {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }

  
  .muzeo-object-images-actions button {
    background-color: white;
    margin-bottom: 0.5rem;
  }
  